import React from 'react';
import { HeaderLogo } from './components/HeaderLogo';
import './BrandLogo.scss';

export const BrandLogo = () => {
  return (
    <div className="BrandLogo d-flex justify-content-center">
      <div className="d-flex content-flex-end">
        <span className="navbar-brand">
          <HeaderLogo />
        </span>
      </div>
      <div className="d-flex">
        <div className="d-flex justify-content-center brandLogo">
          <h2>Get U There</h2>
          <h5>Senior Services</h5>
        </div>
      </div>
    </div>
  );
};
