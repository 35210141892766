import React from 'react';

import './ContactInfoColumn.scss';

export const ContactInfoColumn = ({ columnTitle, columnData, flexEnd }) => {
  const rowClass = 'row ';

  return (
    <div className="ContactInfoColumn container-fluid">
      <div className={flexEnd ? `${rowClass} content-flex-end` : rowClass}>
        <h5 className="columnTitle">
          <strong>{columnTitle}</strong>
        </h5>
      </div>
      {columnData.map((data, i) => {
        return (
          <div key={`${data}${i}`} className={flexEnd ? `${rowClass} content-flex-end` : rowClass}>
            <h6 className="columnData">{data}</h6>
          </div>
        );
      })}
    </div>
  );
};
