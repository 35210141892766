import React from 'react';
import FiveStars from '../../../assets/ReviewCard/fiveStars.png';
import Quote from '../../../assets/ReviewCard/Quote.png';
import { Review } from './component';
import './ReviewCard.scss';

export const ReviewCard = ({ reviewDescription, reviewAuthor }) => {
  const reviewCardProps = { reviewDescription, reviewAuthor };

  return (
    <div className="ReviewCard">
      <img className="leftQuote" src={Quote} alt="Quote" />
      <img className="fiveStars" src={FiveStars} alt="Five Stars" />
      <img className="rightQuote" src={Quote} alt="Quote" />
      <div className="innerFlex">
        <Review {...reviewCardProps} />
      </div>
    </div>
  );
};
