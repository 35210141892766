import React, { Component } from 'react';
import SideNav, { MenuIcon } from 'react-simple-sidenav';
import { BrandLogo, NavItems } from './components';
import { Navbar, Nav } from 'reactstrap';
import './Navigation.scss';
import { NavLink } from 'react-router-dom';

export class Navigation extends Component {
  state = {
    showNav: false
  };

  closeNavigation = () => {
    this.setState({ showNav: false });
  };

  render() {
    return (
      <div className="Navigation row" data-scroll-header="">
        <div className="col-12 col-md-12 col-xl-8 offset-xl-4">
          <Navbar expand="md" className="row">
            <Nav className="col-10 col-md-4">
              <li className="col-12 px-0">
                <NavLink className="navbarBrandLogo" exact to="/">
                  <BrandLogo />
                </NavLink>
              </li>
            </Nav>
            <Nav className="col-2 col-md-8 push-end" navbar>
              <li className="d-none d-md-inline-flex">
                <NavItems />
              </li>
              <li className="d-inline-flex d-md-none MenuIcon">
                <MenuIcon onClick={() => this.setState({ showNav: true })} />
              </li>
            </Nav>
          </Navbar>
        </div>
        <div className="SideNav">
          <SideNav
            className="SideNav"
            openFromRight={true}
            showNav={this.state.showNav}
            onHideNav={() => this.setState({ showNav: false })}
            title={<BrandLogo />}
            items={[<NavItems closeNavigation={this.closeNavigation} />]}
          />
        </div>
      </div>
    );
  }
}
