import React from 'react';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './NavItems.scss';

export const NavItems = ({ closeNavigation }) => {
  return (
    <ul className="NavItems d-md-inline-flex">
      <NavLink onClick={closeNavigation} className="nav-link" exact to="/">
        <NavItem>Home</NavItem>
      </NavLink>
      <NavLink onClick={closeNavigation} className="nav-link" to="/About-Us">
        <NavItem>About Us</NavItem>
      </NavLink>
      <NavLink onClick={closeNavigation} className="nav-link" to="/Contact-Us">
        <NavItem>Contact Us</NavItem>
      </NavLink>
      <NavLink onClick={closeNavigation} className="nav-link" to="/Services">
        <NavItem>Services</NavItem>
      </NavLink>
    </ul>
  );
};
