import React from 'react';

import { ContactInfoColumn, CopyRight, FooterLogo } from './components';
import { NavLink } from 'react-router-dom';

import './Footer.scss';

const contactUsArray = [];
contactUsArray.push(<a href="tel:1-204-955-9292">204-955-9292 (Lisa)</a>);
contactUsArray.push(<a href="tel:1-204-999-7322">204-999-7322 (Anna)</a>);
contactUsArray.push(<a href="mailto:getuthere@shaw.ca">getuthere@shaw.ca</a>);
const contactUs = { columnTitle: 'contact us', columnData: contactUsArray, flexEnd: false };
const Footer = () => {
  const quickLinkArray = [];
  quickLinkArray.push(
    <NavLink exact to="/">
      Home
    </NavLink>
  );
  quickLinkArray.push(<NavLink to="/About-Us">About Us</NavLink>);
  quickLinkArray.push(<NavLink to="/Contact-Us">Contact Us</NavLink>);

  const quickLinks = { columnTitle: 'quick links', columnData: quickLinkArray, flexEnd: true };

  return (
    <div className="Footer row d-flex justify-content-center pt-4">
      <div className="col-11 col-md-4 col-xl-3 text-center align-self-center desktop-center mb-3 mb-md-0">
        <FooterLogo />
      </div>
      <div className="col-6 col-md-3 col-lg-2 text-md-center align-self-center text-right desktop-center">
        <ContactInfoColumn {...quickLinks} />
      </div>
      <div className="col-6 col-md-3 text-md-center col-xl-2 align-self-center desktop-center">
        <ContactInfoColumn {...contactUs} />
      </div>
      <div className="col-12 text-center my-2">
        <CopyRight />
      </div>
    </div>
  );
};

export { Footer, contactUsArray };
