import React from 'react';

import './Review.scss';

export const Review = ({ reviewDescription, reviewAuthor }) => {
  return (
    <div className="Review">
      <div className="d-flex justify-content-center">
        <div className="col-11 text-center">
          <p>{reviewDescription}</p>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="col-7">
          <hr className="horizontalRule" />
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div className="col-11 text-center">
          <p>
            <strong>{reviewAuthor}</strong>
          </p>
        </div>
      </div>
    </div>
  );
};
