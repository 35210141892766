import React from 'react';
import Slider from 'react-slick';
import Img from 'react-image';
import imageSlidesArray from '../../assets/sliderImages';
import './ImageSlider.scss';

export const ImageSlider = () => {
  const settings = {
    adaptiveHeight: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    lazyLoad: true,
    slidesToScroll: 1
  };

  return (
    <div className="ImageSlider row">
      <Slider {...settings}>
        {imageSlidesArray.map(img => {
          return <Img key={img} src={img} alt="Client standing in front of Get U There Senior Services Van" />;
        })}
      </Slider>
    </div>
  );
};
